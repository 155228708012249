<template>
	<div class="pageview">
		<div class="pageviewtitle">
			<div class="titletab">
				<el-icon @click="backpath">
					<ArrowLeft />
				</el-icon>添加题目
			</div>
			<el-button type="primary" size="default" style="width: 120px;" @click="submitadd" :loading="addloading">保存
			</el-button>
		</div>
		<el-scrollbar>
			<div style="margin-top: 20px;box-sizing: border-box;padding-right: 50px;">
				<div class="row" v-if="isparentid">
					<div class="row-title">在【{{dd}}】下增加小题</div>
				</div>
				<div class="row">
					<div class="row-title">题型<el-tag type="danger" size="small">必填</el-tag>
					</div>
					<el-select clearable v-model="addjson.questionType" @change="typechange">
						<el-option label="单选题" value="1"></el-option>
						<el-option label="多选题" value="2"></el-option>
						<el-option label="判断题" value="3"></el-option>
						<el-option label="填空题" value="4"></el-option>
						<el-option label="主观题" value="5"></el-option>
					</el-select>
				</div>
				<div v-show="isshownext()">
					<div class="row">
						<div class="row-title">题目<el-tag type="danger" size="small">必填</el-tag>
						</div>
						<el-input v-model="addjson.questionName" placeholder="请输入题目" clearable></el-input>
					</div>
					<div class="row">
						<div class="row-title">分数<el-tag type="danger" size="small">必填</el-tag>
						</div>
						<el-input-number :min="0" :precision="0" v-model="addjson.score" />
					</div>
					<div class="row">
						<div class="row-title">难度<el-tag type="danger" size="small">必填</el-tag>
						</div>
						<el-select clearable v-model="addjson.difficulty">
							<el-option label="超简单" value="0"></el-option>
							<el-option label="简单" value="1"></el-option>
							<el-option label="正常" value="2"></el-option>
							<el-option label="难" value="3"></el-option>
							<el-option label="超难" value="4"></el-option>
						</el-select>
					</div>
					<div class="row">
						<div class="row-title">年级<el-tag type="danger" size="small">必填</el-tag>
						</div>
						<el-select clearable v-model="addjson.gradeId">
							<el-option v-for="item in gradeslist" :label="item.gradeName" :value="item.id"
								:key="item.id"></el-option>
						</el-select>
					</div>
					<div class="row">
						<div class="row-title">学科<el-tag type="danger" size="small">必填</el-tag>
						</div>
						<el-select clearable v-model="addjson.subjectId">
							<el-option v-for="item in subjectslist" :label="item.subjectName" :value="item.id"
								:key="item.id"></el-option>
						</el-select>
					</div>
					<div class="row">
						<div class="row-title">知识层级<el-tag type="danger" size="small">必填</el-tag>
						</div>
						<el-select clearable v-model="addjson.cognitiveLevelId">
							<el-option v-for="item in cognitivelevellist" :label="item.cognitiveName" :value="item.id"
								:key="item.id"></el-option>
						</el-select>
					</div>
					<div class="row">
						<div class="row-title">知识点<el-tag type="danger" size="small">必填</el-tag>
						</div>
						<el-cascader ref="zsd" style="width: 30%;" @change="zsdchange" :options="knowledgetree"
							:props="props2" clearable />
					</div>
					<div class="row">
						<div class="row-title">量规<el-tag type="danger" size="small">必填</el-tag>
						</div>
						<el-cascader ref="lgui" v-model="liangguicheck" @change="lgchange" style="width: 30%;"
							:options="rubricstree" :props="props" clearable />
						<div v-if="lgnum != 0"
							style="display: flex;align-items: center;flex-wrap: wrap;padding-top: 20px;">
							<!-- <div style="width: 25%;padding: 10px 20px;" v-for="item in addjson.questionRubricDtoList"
								:key="item.rubricId">
								<div style="text-align: center;padding-bottom: 10px;">{{item.rubricName}} <el-input v-model="item.weight" placeholder="量规权重"></el-input></div>
								<div style="display: flex;align-items: center;justify-content: center;padding-bottom: 10px;"
									v-for="item2 in item.questionRubricOptions" :key="item2">
									<el-input v-model="item2.optionName" style="width: 250px;" placeholder="量规选项名称">
									</el-input>
									<el-input v-model="item2.optionDesc" style="width: 250px;margin-left: 10px;"
										placeholder="量规选项描述"></el-input>
									<el-input v-model="item2.weight" style="width: 250px;margin-left: 10px;"
										placeholder="量规选项权重"></el-input>
								</div>
							</div> -->
							<div class="lgmainbox" :style="'width:' + 200*(lgdjnum+1) + 'px'">
								<div class="lgbox">
									<div class="lgitem">
										<div class="lginput fbox">
											<div class="topname lgname">量规选项</div>
											<div class="bottomname lgname">量规名称</div>
											<div class="line"></div>
										</div>
										<div class="lginput headinput" v-for="lgdjitem in jgxxlist" :key="lgdjitem">
											(<input v-model="lgdjitem.optionName" placeholder="量规选项名称" />)
											(<input v-model="lgdjitem.weight" placeholder="权重" />)
										</div>
									</div>
									<div class="lgitem" v-for="lgitem in addjson.questionRubricDtoList" :key="lgitem">
										<div class="lginput headinput">
											(<input v-model="lgitem.rubricName" disabled />)
											(<input v-model="lgitem.weight" placeholder="权重" />)
										</div>
										<input class="lginput" v-for="initem in lgitem.questionRubricOptions"
											:key="initem" placeholder="请输入描述" v-model="initem.optionDesc" />
									</div>
								</div>
								<div class="lgbtnright" style="margin-left: 10px;">
									<el-button type="success" size="small" @click="lgdjnumjia">添加</el-button>
									<br>
									<el-button type="danger" size="small" @click="lgdjnumjian">删除</el-button>
								</div>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="row-title">题干<el-tag type="danger" size="small">必填</el-tag>
						</div>
						<div ref="editor1"></div>
					</div>
					<div class="row">
						<div class="row-title">解析<el-tag type="danger" size="small">必填</el-tag>
						</div>
						<div ref="editor2"></div>
					</div>
					<div class="row" v-show="addjson.questionType == 4 || addjson.questionType == 5">
						<div class="row-title">参考答案<el-tag type="danger" size="small">必填</el-tag>
						</div>
						<div ref="editor3"></div>
					</div>
					<div class="row"
						v-show="addjson.questionType == 1 || addjson.questionType == 2 || addjson.questionType == 3">
						<div class="row-title">参考答案<el-tag type="danger" size="small">必填</el-tag>
						</div>
						<el-input v-model="addjson.answer" placeholder="请输入答案" clearable></el-input>
					</div>
					<!-- 单选题 -->
					<div v-show="addjson.questionType == 1">
						<div class="row" style="margin-top: 20px;">
							<div class="row-title" style="padding-bottom: 20px;">选项<el-tag type="danger" size="small">必填
								</el-tag>
								<el-button size="small" type="success" style="margin-left: 20px;"
									@click="addquestionOption">添加选项
								</el-button>
							</div>
							<div style="padding-left: 40px;">
								<!-- 这里是选项 -->
								<div style="margin-bottom: 10px;" v-for="item in addjson.questionOptionList"
									:key="item.optionName">
									<!-- 选项1 -->
									<label>{{item.optionName}}</label>.
									<el-input v-model="item.optionContent" style="width: 300px;"
										placeholder="请输入选项内容" />
								</div>

							</div>
							<el-button style="margin-left: 40px;" type="danger" size="small"
								@click="removequestionOption">删除选项</el-button>
						</div>
					</div>
					<!-- 多选题 -->
					<div v-show="addjson.questionType == 2">
						<div class="row" style="margin-top: 20px;">
							<div class="row-title" style="padding-bottom: 20px;">选项<el-tag type="danger" size="small">必填
								</el-tag>
								<el-button size="small" type="success" style="margin-left: 20px;"
									@click="addquestionOption">添加选项
								</el-button>
							</div>
							<div style="padding-left: 40px;">
								<!-- 这里是选项 -->
								<div style="margin-bottom: 10px;" v-for="item in addjson.questionOptionList"
									:key="item.optionName">
									<!-- 选项1 -->
									<label>{{item.optionName}}</label>.
									<el-input v-model="item.optionContent" style="width: 300px;"
										placeholder="请输入选项内容" />
								</div>

							</div>
							<el-button style="margin-left: 40px;" type="danger" size="small"
								@click="removequestionOption">删除选项</el-button>
						</div>
					</div>
					<!-- 判断题 -->
					<div v-show="addjson.questionType == 3">
						<div class="row" style="margin-top: 20px;">
							<div class="row-title" style="padding-bottom: 20px;">选项<el-tag type="danger" size="small">必填
								</el-tag>
								<el-button size="small" type="success" style="margin-left: 20px;"
									@click="addquestionOption">添加选项
								</el-button>
							</div>
							<div style="padding-left: 40px;">
								<!-- 这里是选项 -->
								<div style="margin-bottom: 10px;" v-for="item in addjson.questionOptionList"
									:key="item.optionName">
									<!-- 选项1 -->
									<label>{{item.optionName}}</label>.
									<el-input v-model="item.optionContent" style="width: 300px;"
										placeholder="请输入选项内容" />
								</div>

							</div>
							<el-button style="margin-left: 40px;" type="danger" size="small"
								@click="removequestionOption">删除选项</el-button>
						</div>
					</div>
					<!-- 填空题 -->
					<div v-show="addjson.questionType == 4">
						填空题
					</div>
					<!-- 主观题 -->
					<div v-show="addjson.questionType == 5">
						主观题
					</div>
				</div>
			</div>
		</el-scrollbar>
	</div>
</template>

<script>
	import axios from 'axios'
	import E from 'wangeditor'
	import config from '../../util/config'
	import {
		ArrowLeft,
		Plus
	} from '@element-plus/icons-vue'
	import {
		gradeslist,
		subjectslist,
		cognitivelevellist,
		rubricstree,
		knowledgetree,
		attachmentsupload,
		questionsadd
	} from "../../util/api"
	export default {
		components: {
			ArrowLeft,
			Plus
		},
		data() {
			return {
				addloading: false, //添加按钮是否loading
				gradeslist: null, //年级列表
				subjectslist: null, //学科列表
				cognitivelevellist: null, //认知层级列表
				rubricstree: null, //量规树
				knowledgetree: null, //知识点树
				props: {
					label: 'rubricName',
					value: 'id',
					children: 'children',
					multiple: true,
					expandTrigger: 'hover'
				},
				props2: {
					label: 'knowledgeName',
					value: 'id',
					children: 'children',
					multiple: true,
					expandTrigger: 'hover'
				},
				liangguicheck: null,
				isparentid: false,
				dd: null,
				addjson: {
					questionName: null, //题目名称
					stem: null, //题干
					questionType: '1', //题目类型: 1:单选, 2: 多选, 3: 判断, 4: 填空，5: 主观
					score: 0, //分数
					answer: null, //答案
					analysis: null, //解析
					difficulty: null, //难度 0超简单  1简单  2正常  3难  4超难
					gradeId: null, //年级
					subjectId: null, //学科
					parentId: null, //父节点
					cognitiveLevelId: null, //认知层级
					attachmentIds: [], //题目附件 ID [1,2,3]
					questionOptionList: [], //题目选项
					questionKnowledgeRelations: [], //知识点
					questionRubricDtoList: [] //量规
				},
				lgnum: 0, //已选择的量规数量
				lgdjnum: 4, //量规选项数量  默认4   水平0  --  水平4
				jgxxlist: [
					{
						optionName: null, //选项名称
						optionDesc: null,
						weight: null
					},
					{
						optionName: null, //选项名称
						optionDesc: null,
						weight: null
					},
					{
						optionName: null, //选项名称
						optionDesc: null,
						weight: null
					},
					{
						optionName: null, //选项名称
						optionDesc: null,
						weight: null
					}
				]
			}
		},
		created() {
			if (this.$route.query.id) {
				this.addjson.parentId = this.$route.query.id
				this.dd = this.$route.query.name
				this.isparentid = true
			} else {
				this.addjson.parentId = null
				this.isparentid = false
			}
			this.getgradeslist()
			this.getsubjectslist()
			this.getcognitivelevellist()
			this.getrubricstree()
			this.getknowledgetree()
		},
		methods: {
			log() {
				this.addjson.questionRubricDtoList.forEach((item,index)=>{
					item.questionRubricOptions.forEach((item2,index2)=>{
						if(item2.optionDesc != null && item2.optionDesc != ''){
							item2.optionName = this.jgxxlist[index2].optionName
							item2.weight = this.jgxxlist[index2].weight
						}
					})
				})
			},
			lgdjnumjia() { //量规等级 加
				if (this.lgdjnum < 6) {
					this.lgdjnum += 1
					this.jgxxlist = [...this.jgxxlist, {
						optionName: null, //选项名称
						optionDesc: null,
						weight: null //权重
					}]
					this.addjson.questionRubricDtoList.forEach((item) => {
						item.questionRubricOptions = [...item.questionRubricOptions, {
							optionName: null, //选项名称
							optionDesc: null, //选项描述
							weight: null //权重}]
						}]
					})
				}
			},
			lgdjnumjian() { // 量规等级 减
				if (this.lgdjnum > 2) {
					this.lgdjnum -= 1
					this.jgxxlist.pop()
					this.addjson.questionRubricDtoList.forEach((item) => {
						item.questionRubricOptions.pop()
					})
				}
			},
			submitadd() {
				this.log()
				questionsadd(this.addjson).then((res) => {
					this.$message({
						type: 'success',
						message: '添加成功',
					})
				})
			},
			zsdchange() {
				let newlist = []
				this.$refs.zsd.getCheckedNodes(true).forEach((item) => {
					newlist.push({
						knowledgeId: item.value
					})
				})
				this.addjson.questionKnowledgeRelations = newlist
			},
			lgchange(value) { //当量规发生变化  组合到addjson
				this.lgnum = value.length
				let newlist = []
				this.$refs.lgui.getCheckedNodes(true).forEach((item) => {
					newlist.push({
						rubricId: item.value, //量规ID
						rubricName: item.label,
						weight: null, //权重   小数
						questionRubricOptions: [{
							optionName: null, //选项名称
							optionDesc: null, //选项描述
							weight: null //权重}]
						}, {
							optionName: null, //选项名称
							optionDesc: null, //选项描述
							weight: null //权重}]
						}, {
							optionName: null, //选项名称
							optionDesc: null, //选项描述
							weight: null //权重}]
						}, {
							optionName: null, //选项名称
							optionDesc: null, //选项描述
							weight: null //权重}]
						}] //量规选项
					})
				})
				this.addjson.questionRubricDtoList = newlist
			},
			removequestionOption() { //删除最后一位选项
				let newlist = []
				this.addjson.questionOptionList.forEach((item, index) => {
					if (index < this.addjson.questionOptionList.length - 1) {
						newlist.push(item)
					}
				})
				this.addjson.questionOptionList = newlist
			},
			addquestionOption() { //添加选项
				if (this.addjson.questionOptionList.length == 0) {
					this.addjson.questionOptionList.push({
						optionName: 'A', //选项名字 ABC
						optionContent: null //选项内容
					})
				} else if (this.addjson.questionOptionList.length == 1) {
					this.addjson.questionOptionList.push({
						optionName: 'B', //选项名字 ABC
						optionContent: null //选项内容
					})
				} else if (this.addjson.questionOptionList.length == 2) {
					this.addjson.questionOptionList.push({
						optionName: 'C', //选项名字 ABC
						optionContent: null //选项内容
					})
				} else if (this.addjson.questionOptionList.length == 3) {
					this.addjson.questionOptionList.push({
						optionName: 'D', //选项名字 ABC
						optionContent: null //选项内容
					})
				} else if (this.addjson.questionOptionList.length == 4) {
					this.addjson.questionOptionList.push({
						optionName: 'E', //选项名字 ABC
						optionContent: null //选项内容
					})
				} else if (this.addjson.questionOptionList.length == 5) {
					this.addjson.questionOptionList.push({
						optionName: 'F', //选项名字 ABC
						optionContent: null //选项内容
					})
				}

			},
			typechange() { //当题型发生改变  清空答案
				this.addjson.answer = null
			},
			isshownext() {
				if (!this.addjson.questionType) {
					return false
				} else {
					return true
				}
			},
			getknowledgetree() {
				knowledgetree().then((res) => {
					this.knowledgetree = res.data
				})
			},
			getrubricstree() {
				rubricstree().then((res) => {
					this.rubricstree = res.data
				})
			},
			getcognitivelevellist() {
				cognitivelevellist({
					pageNum: 1,
					pageSize: 99999
				}).then((res) => {
					this.cognitivelevellist = res.data.list
				})
			},
			getsubjectslist() { //获取列表
				subjectslist({
					pageNum: 1,
					pageSize: 99999
				}).then((res) => {
					this.subjectslist = res.data.list
				})
			},
			getgradeslist() {
				gradeslist({
					pageNum: 1,
					pageSize: 99999
				}).then((res) => {
					this.gradeslist = res.data.list
				})
			},
			backpath() {
				this.$router.back()
			}
		},
		mounted() {
			const menusconfig = [
				'head',
				'bold',
				'fontSize',
				'fontName',
				'italic',
				'underline',
				'strikeThrough',
				'indent',
				'lineHeight',
				'foreColor',
				'backColor',
				'link',
				'list',
				'todo',
				'justify',
				'quote',
				'emoticon',
				'image',
				'table',
				'code',
				'splitLine',
				'undo',
				'redo'
			]
			const showLinkImg = false
			const showFullScreen = false

			// 题干
			const editor1 = new E(this.$refs.editor1)
			editor1.config.zIndex = 0
			editor1.config.menus = menusconfig
			editor1.config.showLinkImg = showLinkImg
			editor1.config.showFullScreen = showFullScreen
			editor1.config.onchange = (newHtml) => {
				if (newHtml) {
					this.addjson.stem = newHtml
				} else {
					this.addjson.stem = null
				}
			}
			editor1.config.uploadImgMaxLength = 1
			editor1.config.customUploadImg = function(resultFiles, insertImgFn) {
				let formData = new FormData()
				formData.append('file', resultFiles[0])
				formData.append('AttachType', '4')
				formData.append('bizId', '3')
				attachmentsupload(formData).then((res) => {
					insertImgFn('https://' + res.data.uploadResult)
				})
			}
			editor1.create()

			//解析
			const editor2 = new E(this.$refs.editor2)
			editor2.config.zIndex = 0
			editor2.config.menus = menusconfig
			editor2.config.showLinkImg = showLinkImg
			editor2.config.showFullScreen = showFullScreen
			editor2.config.onchange = (newHtml) => {
				if (newHtml) {
					this.addjson.analysis = newHtml
				} else {
					this.addjson.analysis = null
				}
			}
			editor2.config.uploadImgMaxLength = 1
			editor2.config.customUploadImg = function(resultFiles, insertImgFn) {
				let formData = new FormData()
				formData.append('file', resultFiles[0])
				formData.append('AttachType', '4')
				formData.append('bizId', '3')
				attachmentsupload(formData).then((res) => {
					insertImgFn('https://' + res.data.uploadResult)
				})
			}
			editor2.create()

			//答案
			const editor3 = new E(this.$refs.editor3)
			editor3.config.zIndex = 0
			editor3.config.menus = menusconfig
			editor3.config.showLinkImg = showLinkImg
			editor3.config.showFullScreen = showFullScreen
			editor3.config.onchange = (newHtml) => {
				if (newHtml) {
					this.addjson.answer = newHtml
				} else {
					this.addjson.answer = null
				}
			}
			editor3.config.uploadImgMaxLength = 1
			editor3.config.customUploadImg = function(resultFiles, insertImgFn) {
				let formData = new FormData()
				formData.append('file', resultFiles[0])
				formData.append('AttachType', '4')
				formData.append('bizId', '3')
				attachmentsupload(formData).then((res) => {
					insertImgFn('https://' + res.data.uploadResult)
				})
			}
			editor3.create()
		}
	}
</script>

<style scoped>
	.questionname {
		padding-top: 10px;
	}

	.xt {
		padding-top: 10px;
	}

	.el-scrollbar {
		height: calc(100vh - 150px);
	}

	.xximg {
		margin-top: 10px;
	}

	.ppor {
		z-index: 99 !important;
	}


	.lgitem {
		display: flex;
		align-items: center;
		width: 100%;
	}

	.lginput {
		width: 200px;
		height: 60px;
		padding: 5px;
		margin: 0;
		border: 1px solid #333333;
		outline-style: none;
		text-align: center;
		box-sizing: border-box;
	}

	.fbox {
		position: relative;
		overflow: hidden;
	}

	.topname {
		position: absolute;
		right: 0;
		top: 5px;
	}

	.line {
		width: 100%;
		height: 1px;
		background: #333333;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		transform: rotate(45deg);
	}

	.bottomname {
		position: absolute;
		bottom: 5px;
		left: 0;
	}

	.lgname {
		width: 100px
	}

	.lgmainbox {
		display: flex;
		align-items: center;
	}

	.headinput {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.headinput input {
		border: none;
		border-bottom: 1px solid #cccccc;
		padding: 5px;
		margin: 0;
		outline-style: none;
		text-align: center;
		box-sizing: border-box;
	}

	.headinput input:first-child {
		width: 120px;
	}

	.headinput input:last-child {
		width: 35px;
	}
</style>
